import { defineStore } from "pinia";
import { useSyncStore } from "~/stores/SyncStore";
import { useNuxtApp } from "#app";
import { useAuthStore } from "~/stores/AuthStore";
import {useTicketStore} from "~/stores/TicketStore";

export const useEventStore = defineStore("eventStore", {
  state: () => ({
    events: [],
    selectedEventId: null,
  }),
  getters: {
    selectedEvent() {
      return this.selectedEventId === null
        ? null
        : this.events.find((event) => event.id === this.selectedEventId);
    },
    selectedEventTicketStats() {

      if (this.selectedEvent !== null) {
        const ticketStore = useTicketStore();
        return {
          ticketsSold: ticketStore.ticketsSold,
          ticketsScanned: ticketStore.ticketsScanned,
          premiumEntries: ticketStore.premiumEntries,
        };
      } else {
        return null;
      }
    },
  },
  actions: {
    async getEvents() {
      const store = useEventStore();
      const { $api } = useNuxtApp();
      const authStore = useAuthStore();

      const response = await $api
        .get("/event", {
          headers: {
            Authorization: "Bearer " + authStore.token,
          },
        })
        .then(function (response) {
          store.events = response.data.data;
        })
        .catch(function (error) {
          // throw error;
        });
    },
    selectEvent(eventId) {
      this.selectedEventId = eventId;
    },
  },
  persist: {
    key: "eventStore",
    storage: localStorage,
  },
});
