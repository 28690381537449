import axios from "axios";
import { useAuthStore } from "~/stores/AuthStore";
import { createPinia, storeToRefs } from "pinia";
import App from "~/app.vue";
import {useSyncStore} from "~/stores/SyncStore";
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const defaultUrl = runtimeConfig.public.server + "/api";
  const authStore = useAuthStore();
  const syncStore = useSyncStore();

  const api = axios.create({
    baseURL: defaultUrl,
    headers: {
      accept: "application/json",
    },
  });


  api.interceptors.request.use(function (config) {
    syncStore.startTransfer();
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


  api.interceptors.response.use(
    function (response) {
      syncStore.endTransfer();
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        // const authStore = useAuthStore();
        authStore.logout()
      }
      return Promise.reject(error);
    }
  );

  return {
    provide: {
      api: api,
    },
  };
});
