import { defineStore } from "pinia";
import { useNuxtApp } from "#app";
import { useAuthStore } from "~/stores/AuthStore";

export const useTicketStore = defineStore("ticketStore", {
  state: () => ({
    eventId: null,
    tickets: {},
    premiumMemberships: {},
  }),
  getters: {
    ticketsSold: function () {
      return this.tickets.length;
    },
    ticketsScanned: function () {
      return Object.values(this.tickets).filter((ticket) => ticket.scanned_at !== null).length;
    },
    premiumEntries: function () {
      return Object.values(this.premiumMemberships).filter((membership) => membership.scanned_at !== null).length;
    }
  },
  actions: {
    getTickets: async function (eventId) {
      const store = useTicketStore();

      store.eventId = eventId;

      const authStore = useAuthStore();
      const { $api } = useNuxtApp();

      const response = await $api
        .get("/event/" + eventId + "/tickets", {
          headers: {
            Authorization: "Bearer " + authStore.token,
          },
        })
        .then(function (response) {
          store.tickets = response.data.tickets.data;
          store.premiumMemberships = response.data.premiumMemberships;
        })
        .catch(function (error) {
          // throw error;
        });
    },
    updateTicket: async function (ticketUuid) {
      const authStore = useAuthStore();
      const { $api } = useNuxtApp();

      const response = await $api
        .patch(
          "/ticket/" + ticketUuid,
          {},
          {
            headers: {
              Authorization: "Bearer " + authStore.token,
            },
          }
        )
        .then(function (response) {
          store.tickets = response.data.data;
        })
        .catch(function (error) {
          // throw error;
        });
    },
    premiumEntry: async function (eventId, ref) {
      const authStore = useAuthStore();
      const { $api } = useNuxtApp();

      const response = await $api
          .post(
              "/event/"+eventId+"/vip",
              {
                ref: ref
              },
              {
                headers: {
                  Authorization: "Bearer " + authStore.token,
                },
              }
          )
          .then(function (response) {
            store.premiumMemberships = response.data.data;
          })
          .catch(function (error) {
            // throw error;
          });
    },

    scanTicket(ticketRefUnescaped) {
        let ticketRef = ticketRefUnescaped.replace(/['"]+/g, '');

      // Check for premium membership
      let premiumMembership = this.premiumMemberships.find((t) => t.ref === ticketRef);
      if(premiumMembership !== undefined && premiumMembership !== null){
        // Check if the user has already been scanned
        if(premiumMembership.scanned_at !== null){
          return {
            result: "fail",
            message: "Already Scanned",
            subMessage: premiumMembership.scanned_at,
            avatar: premiumMembership.avatar !== '' ? premiumMembership.avatar : null,
            showMoreName: null
          };
        }


        // if not, let them in and mark them as scanned
        premiumMembership.scanned_at = new Date();

        this.premiumEntry(this.eventId, premiumMembership.ref);

        return {
          result: "scanSuccess",
          message: "Black Card Holder",
          subMessage: premiumMembership.name,
          avatar: premiumMembership.avatar !== '' ? premiumMembership.avatar : null,
          showMoreName: null
        };
      }


      let ticket = this.tickets.find((t) => t.ref.trim() === String(ticketRef).trim());

      if (ticket === undefined || ticket === null) {
        return {
          result: "fail",
          message: "Ticket Not Found",
          subMessage: null,
          showMoreName: null
        };
      }

      let ticketsLeft = this.tickets.filter((t) => {
        return t.name === ticket.name && t.scanned_at === null;
      })



      if (ticket.scanned_at !== null) {
        return {
          result: "fail",
          message: "Already Scanned",
          subMessage: ticket.scanned_at.toLocaleString(),
          showMoreName: ticketsLeft.length > 0 ? Object.values(ticketsLeft)[0].name : null,
        };
      }

      ticket.scanned_at = new Date();
      this.updateTicket(ticket.uuid);

      ticketsLeft = this.tickets.filter((t) => {
        return t.name === ticket.name && t.scanned_at === null;
      })

      return {
        result: "scanSuccess",
        message: "Scanned!",
        subMessage: "(" + ticket.name + " : " + ticketsLeft.length + " remaining)",
        showMoreName: ticketsLeft.length > 0 ? Object.values(ticketsLeft)[0].name : null,
      };
    },
    searchTickets(query) {
      console.log(query);
    },
  },
});
