import { defineStore } from "pinia";
import axios from "axios";
import { useNuxtApp } from "#app";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    email: null,
    token: null,
    user: {
      email: null,
      name: null,
      image: null,
    },
    expires: null,
  }),
  getters: {
    authenticated() {
      return this.token !== null;
    },
  },
  actions: {
    async getUser() {
      const store = useAuthStore();
      let url = "http://doorscan-server.test/api/user";
      const { $api } = useNuxtApp();
      await $api
        .get("/user", {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        })
        .then(function (response) {
          store.user = response.data;
        })
        .catch(function (error) {
          throw error;
        });
    },
    async logout() {
      // kill the token
      // this.killTokens(this.user.email);

      // clear the authStore
      const store = useAuthStore();
      store.$reset();

    },
    async login(email, password) {
      const store = useAuthStore();
      await this.getToken(email, password)
        .then(function (response) {
          store.getUser();
        })
        .catch(function (error) {
          throw error;
        });
    },
    async getToken(email, password) {
      const { $api } = useNuxtApp();
      await $api
        .post("/sanctum/token", {
          email: email,
          password: password,
          device_name: "DoorScan Client - Web - v1",
        })
        .then(function (response) {
          const store = useAuthStore();
          store.token = response.data;
        })
        .catch(function (error) {
          throw error;
        });
    },
    async killTokens(email) {
      const { $api } = useNuxtApp();
      await $api
        .delete("/sanctum/token", {
          data: {
            email: email,
          },
        })
        .then(function (response) {})
        .catch(function (error) {
          throw error;
        });
    },
  },
  persist: {
    key: 'authStore',
    storage: localStorage,
  },
});
