import { defineStore } from "pinia";

export const useSyncStore = defineStore("syncStore", {
  state: () => ({
    transferInProgress: false,
  }),
  actions: {
    startTransfer(){
      this.transferInProgress = true;
    },
    endTransfer(){
      this.transferInProgress = false;
    },
  },
});
