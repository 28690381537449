import { default as eventscJ3PzTVIiKMeta } from "/workspace/pages/events.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as scanKgiBWCmGncMeta } from "/workspace/pages/scan.vue?macro=true";
export default [
  {
    name: "events",
    path: "/events",
    component: () => import("/workspace/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "scan",
    path: "/scan",
    component: () => import("/workspace/pages/scan.vue").then(m => m.default || m)
  }
]