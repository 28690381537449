import { useAuthStore } from "~/stores/AuthStore";
import { navigateTo } from "#app";

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();

  if (to.name === "login") {
    if (authStore.authenticated) {
      return navigateTo("/");
    }
  } else {
    if (!authStore.authenticated) {
      return navigateTo("/login");
    }
  }
});
