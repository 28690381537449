import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

export default defineNuxtPlugin(({ $config, vueApp}) => {
    flare.light($config.public.flareKey);
    flare.beforeSubmit = (report) => {
        return {
            ...report,
            application_path: report.context.request?.url,
        };
    };
    vueApp.use(flareVue);
});
