<template>
  <NuxtPage />
</template>
<script setup>
import {useEventStore} from "~/stores/EventStore";


const eventStore = useEventStore();
eventStore.getEvents();

</script>
